import RSVP from "../components/rsvp";
import Invite from "../components/invite";

export default function HomePage() {
    return (
        <main>
            <div className="image-container">
                <img src="/images/us_small.jpeg" alt="" />
            </div>
            <div className="main-inner">
                <Invite />
            </div>
            <div className="box-container line-top line-bottom text-small text-center">
                <div className="main-inner">
                    <div className="text-container row-top row-bottom-rem">
                        <p>~ More details to follow ~</p>
                    </div>
                    <div className="text-small">
						<RSVP></RSVP>
					</div>
                </div>
            </div>
        </main>
    );
}