export default function Invite() {
	return (
		<div className="text-container row-top row-bottom text-center">
			<p>Ask you to save the date</p>
			<p className="text-italic">
				24<sup className="text-super">th</sup>{" "}
				<span className="text-lower">of</span> May 2025
			</p>
			<p>For their wedding at the beautiful</p>
			<p className="text-italic">Fasque House</p>
			<p>Aberdeenshire, Scotland</p>
		</div>
	);
}