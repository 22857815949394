import nine from "../assets/timer/9.min.svg";
import eight from "../assets/timer/8.min.svg";
import seven from "../assets/timer/7.min.svg";
import six from "../assets/timer/6.min.svg";
import five from "../assets/timer/5.min.svg";
import four from "../assets/timer/4.min.svg";
import three from "../assets/timer/3.min.svg";
import two from "../assets/timer/2.min.svg";
import one from "../assets/timer/1.min.svg";
import zero from "../assets/timer/0.min.svg";

export default function CountdownTimer(props) {
    const svgs = [nine, eight, seven, six, five, four, three, two, one, zero];
    const maxSeconds = 9;

    const imgStyle = {
        width: 100,
        height: 70,
    };

    const getSvg = () => {
        return svgs[maxSeconds - props.secondsLeft];
    };

    return (
        <img src={getSvg()} alt="countdown" style={imgStyle}></img>
    );
};