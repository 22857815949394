import { useState, useEffect } from 'react';

import LoadingWheel from './loadingWheel';
import RadioQuestion from './radioQuestion';

import { ApiService } from '../services/apiService';
import { CookieSerivce } from '../services/cookieService';

export default function RSVP() {
    const rsvpCookieName = "rsvpSubmitted";
    const [input, setInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [party, setParty] = useState({});
    const [hiddenParty, setHiddenParty] = useState({});
    const [partySearchName, setPartySearchName] = useState('');

    const [rsvpAnswers, setRsvpAnswers] = useState({});
    const [observerAnswers, setObserverAnswers] = useState({});
    const [allRsvpsDone, setAllRsvpsDone] = useState(false);
    const [allObserversDone, setAllObserversDone] = useState(false);

    const [submitComplete, setSubmitComplete] = useState(CookieSerivce.getCookie(rsvpCookieName) ? true : false);
    const [doGotcha, setDoGotcha] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Use this to clear cookies in debug mode
        // document.cookie = rsvpCookieName + "=false; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    });

    const onRsvpOptionChange = e => {
        let updatedAnswers = rsvpAnswers;
        updatedAnswers[e.idx + "-rsvp"] = e.value;
        setRsvpAnswers(updatedAnswers);
        if (Object.keys(rsvpAnswers).length === party.members.length) {
            setAllRsvpsDone(true);
        }
    }

    const onOserverOptionChange = e => {
        let updatedAnswers = observerAnswers;
        updatedAnswers[e.idx + "-question"] = e.value;
        setObserverAnswers(updatedAnswers);
        if (Object.keys(observerAnswers).length === party.members.length) {
            setAllObserversDone(true);
        }
    }

    const searchForParty = () => {
        if (doGotcha) {
            setErrorMessage("I'm totally messing with ya. You actually entered the name right the first time. And then you typed it again! 😂 Your info is below. It's been here the whole time.");
            setParty(hiddenParty);
            setInput(partySearchName);
            return;
        }

        setIsLoading(true);

        ApiService.getParty(input).then((result) => {
            setErrorMessage('');
            setSubmitComplete(false);
            setIsLoading(false);
            if (result && result.status && result.status === "COMPLETE") {
                setErrorMessage("Looks like you already RSVP-ed. Please reach out to Matt or Fiona if you need to change your status.");
                setInput("");
                return;
            }
            if (!result || !result.members || result.members.length === 0) {
                setErrorMessage("Could not locate your party. Please try again.");
                setInput("");
                return;
            }
            if (result.features && result.features.includes("gotcha")) {
                setErrorMessage("Could not locate your party. Make sure that you're entering the name EXACTLY as it appears on the Save the Date. Like, exactly...");
                setDoGotcha(true);
                setHiddenParty(result);
                setPartySearchName(input);
                setInput("");
                return;
            }

            setParty(result);
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchForParty();
        }
    }

    const submitClicked = () => {
        setIsLoading(true);

        let updatedMembers = party.members.map((item, idx) => {
            item["rsvp_status"] = rsvpAnswers[idx + "-rsvp"];
            item["question_status"] = observerAnswers[idx + "-question"];
            return item;
        });
        let updatedParty = party;
        party["members"] = updatedMembers;

        ApiService.updateParty(updatedParty).then((result) => {
            setInput("");
            setErrorMessage("");
            setIsLoading(false);
            if (result && result.status && result.status === "COMPLETE") {
                setSubmitComplete(true);
                setParty({});
                setHiddenParty({});
                document.cookie = rsvpCookieName + "=true";
                return;
            }

            if (result && result.status && result.status === "ERROR") {
                setErrorMessage("Something went wrong. Please try again later, or reach out to Matt and Fiona directly.");
                return
            }
        });
    }

    return (<div className="rsvp-form">
        {(!submitComplete ?
            <div>
                Please help us collect an initial headcount by responding below.
                <br></br>
                <br></br>
                Enter your name exactly as it appears on your Save the Date (the picture we sent you):
                <input type="text" disabled={isLoading} value={input} onInput={e => setInput(e.target.value)} onKeyDown={handleKeyDown}></input>
                <div className='error'>{errorMessage}</div>
            </div> : <div />)}
        {(!submitComplete && !isLoading && (!party || (party && !party.members) || (party && party.members && party.members.length === 0)) ? <button className="cta" onClick={searchForParty}>Find my RSVP</button> : <div />)}
        {(isLoading ? <LoadingWheel /> : <div />)}
        {(!submitComplete && !isLoading && party && party.members && party.members.map((item, idx) => (
            <div key={idx} className="row-top">
                <div className="text-italic row-bottom-rem">{item.name}</div>
                <form>
                    <RadioQuestion idx={idx} question="Will you be joining us for the best day ever?" onChange={onRsvpOptionChange}></RadioQuestion>
                    <RadioQuestion idx={idx} question="Have you recently seen any subtly hidden carvings in pictures of wood?" onChange={onOserverOptionChange}></RadioQuestion>
                </form>
            </div>
        ), this))}
        {(!submitComplete && !isLoading && party && party.members && party.members.length > 0 ? <div className="row-top row-bottom"><button className="cta" disabled={!allObserversDone || !allRsvpsDone} onClick={submitClicked}>Submit</button></div> : <div />)}
        {(submitComplete ? <div >Thanks for sending us that RSVP info!</div> : <div />)}
    </div>);
}