export default function CastleSVG() {
	return (
		<svg
			version="1.1"
			id="castle-svg"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 327.1 108.3"
			className="illustration"
		>
			<g>
				<g>
					<path
						d="M146.3,14.5v11.1l6.2-2.3V12.1L146.3,14.5z M151.8,13.2v4l-4.7,1.8v-4L151.8,13.2z M147.1,24.5v-4.7l4.7-1.8v4.7
			L147.1,24.5z"
					/>
				</g>
				<g>
					<path d="M175.1,12.1v11.1l6.2,2.3V14.5L175.1,12.1z M180.6,15v4l-4.7-1.8v-4L180.6,15z M175.8,22.7V18l4.7,1.8v4.7L175.8,22.7z" />
				</g>
				<g>
					<path d="M160,10.9v11.7h7.6V10.9H160z M166.9,11.7v4h-6.1v-4H166.9z M160.8,21.9v-5.4h6.1v5.4H160.8z" />
				</g>
				<g>
					<path d="M82.5,33.5v11.7h4.9V33.5H82.5z M86.7,34.3V39h-3.4v-4.7H86.7z M83.3,44.5v-4.7h3.4v4.7H83.3z" />
				</g>
				<g>
					<path d="M106.6,63.7h-6.9v11.7h6.9V63.7z M105.8,64.5v4h-5.4v-4H105.8z M100.4,74.7v-5.4h5.4v5.4H100.4z" />
				</g>
				<g>
					<path d="M121,75.4V63.7h-6.9v11.7H121z M114.8,74.7v-5.4h5.4v5.4H114.8z M120.2,64.5v4h-5.4v-4H120.2z" />
				</g>
				<g>
					<path d="M128.5,75.4h6.9V63.7h-6.9V75.4z M129.2,74.7v-5.4h5.4v5.4H129.2z M134.6,64.5v4h-5.4v-4H134.6z" />
				</g>
				<g>
					<path d="M82.5,71.3h4.9V59.6h-4.9V71.3z M83.3,70.5v-4.7h3.4v4.7H83.3z M86.7,60.3v4.7h-3.4v-4.7H86.7z" />
				</g>
				<g>
					<path d="M82.5,95.3h4.9V83.6h-4.9V95.3z M83.3,94.5v-4.7h3.4v4.7H83.3z M86.7,84.3v4.7h-3.4v-4.7H86.7z" />
				</g>
				<g>
					<path
						d="M152.5,34.7l-6.2,2.3v11.1l6.2-2.3V34.7z M151.8,35.8v4l-4.7,1.8v-4L151.8,35.8z M147.1,47.1v-4.7l4.7-1.8v4.7L147.1,47.1
			z"
					/>
				</g>
				<g>
					<path
						d="M181.3,37.1l-6.2-2.3v11.1l6.2,2.3V37.1z M180.6,37.6v4l-4.7-1.8v-4L180.6,37.6z M175.8,45.3v-4.7l4.7,1.8v4.7L175.8,45.3
			z"
					/>
				</g>
				<g>
					<path d="M167.6,33.5H160v11.7h7.6V33.5z M166.9,34.3v4h-6.1v-4H166.9z M160.8,44.5v-5.4h6.1v5.4H160.8z" />
				</g>
				<g>
					<path d="M167.6,71.3V59.6H160v11.7H167.6z M160.8,70.5v-5.4h6.1v5.4H160.8z M166.9,60.3v4h-6.1v-4H166.9z" />
				</g>
				<g>
					<path d="M240.2,33.5v11.7h4.9V33.5H240.2z M244.3,34.3V39H241v-4.7H244.3z M241,44.5v-4.7h3.4v4.7H241z" />
				</g>
				<g>
					<path d="M199.1,75.4V63.7h-6.9v11.7H199.1z M193,74.7v-5.4h5.4v5.4H193z M198.4,64.5v4H193v-4H198.4z" />
				</g>
				<g>
					<path d="M213.5,75.4V63.7h-6.9v11.7H213.5z M207.4,74.7v-5.4h5.4v5.4H207.4z M212.8,64.5v4h-5.4v-4H212.8z" />
				</g>
				<g>
					<path d="M221,75.4h6.9V63.7H221V75.4z M221.8,74.7v-5.4h5.4v5.4H221.8z M227.2,64.5v4h-5.4v-4H227.2z" />
				</g>
				<g>
					<path d="M240.2,71.3h4.9V59.6h-4.9V71.3z M241,70.5v-4.7h3.4v4.7H241z M244.3,60.3v4.7H241v-4.7H244.3z" />
				</g>
				<g>
					<path d="M240.2,95.3h4.9V83.6h-4.9V95.3z M241,94.5v-4.7h3.4v4.7H241z M244.3,84.3v4.7H241v-4.7H244.3z" />
				</g>
				<g>
					<path d="M121,43.8h-6.9v6.9h6.9V43.8z M120.2,44.6v2.3h-5.4v-2.3H120.2z M114.8,50v-2.3h5.4V50H114.8z" />
				</g>
				<g>
					<path d="M128.5,50.7h6.9v-6.9h-6.9V50.7z M129.2,50v-2.3h5.4V50H129.2z M134.6,44.6v2.3h-5.4v-2.3H134.6z" />
				</g>
				<g>
					<path d="M106.6,43.8h-6.9v6.9h6.9V43.8z M105.8,44.6v2.3h-5.4v-2.3H105.8z M100.4,50v-2.3h5.4V50H100.4z" />
				</g>
				<g>
					<path d="M213.5,43.8h-6.9v6.9h6.9V43.8z M212.8,44.6v2.3h-5.4v-2.3H212.8z M207.4,50v-2.3h5.4V50H207.4z" />
				</g>
				<g>
					<path d="M227.9,43.8H221v6.9h6.9V43.8z M227.2,44.6v2.3h-5.4v-2.3H227.2z M221.8,50v-2.3h5.4V50H221.8z" />
				</g>
				<g>
					<path d="M199.1,43.8h-6.9v6.9h6.9V43.8z M198.4,44.6v2.3H193v-2.3H198.4z M193,50v-2.3h5.4V50H193z" />
				</g>
				<g>
					<path d="M160.7,99.4h6.2v-11h-6.2V99.4z M166.2,98.7h-2v-9.5h2V98.7z M161.4,89.1h2v9.5h-2V89.1z" />
				</g>
				<g>
					<rect x="100.1" y="56.8" width="35" height="0.8" />
				</g>
				<g>
					<rect x="100.1" y="81.5" width="35" height="0.8" />
				</g>
				<g>
					<rect x="191.9" y="56.8" width="35" height="0.8" />
				</g>
				<g>
					<rect x="191.9" y="81.5" width="35" height="0.8" />
				</g>
				<g>
					<path d="M39.4,63.7h-6.9v11.7h6.9V63.7z M38.7,64.5v4h-5.4v-4H38.7z M33.3,74.7v-5.4h5.4v5.4H33.3z" />
				</g>
				<g>
					<path d="M53.8,63.7h-6.9v11.7h6.9V63.7z M53.1,64.5v4h-5.4v-4H53.1z M47.7,74.7v-5.4h5.4v5.4H47.7z" />
				</g>
				<g>
					<path d="M61.3,75.4h6.9V63.7h-6.9V75.4z M62.1,74.7v-5.4h5.4v5.4H62.1z M67.5,64.5v4h-5.4v-4H67.5z" />
				</g>
				<g>
					<path d="M15.4,59.6v11.7h4.9V59.6H15.4z M19.5,60.3v4.7h-3.4v-4.7H19.5z M16.1,70.5v-4.7h3.4v4.7H16.1z" />
				</g>
				<g>
					<path d="M15.4,95.3h4.9V83.6h-4.9V95.3z M16.1,94.5v-4.7h3.4v4.7H16.1z M19.5,84.3v4.7h-3.4v-4.7H19.5z" />
				</g>
				<g>
					<rect x="32.9" y="81.5" width="35" height="0.8" />
				</g>
				<g>
					<path d="M295.1,63.7h-6.9v11.7h6.9V63.7z M294.3,64.5v4h-5.4v-4H294.3z M288.9,74.7v-5.4h5.4v5.4H288.9z" />
				</g>
				<g>
					<path d="M280.7,63.7h-6.9v11.7h6.9V63.7z M280,64.5v4h-5.4v-4H280z M274.5,74.7v-5.4h5.4v5.4H274.5z" />
				</g>
				<g>
					<path d="M266.3,63.7h-6.9v11.7h6.9V63.7z M265.6,64.5v4h-5.4v-4H265.6z M260.2,74.7v-5.4h5.4v5.4H260.2z" />
				</g>
				<g>
					<path d="M307.4,59.6v11.7h4.9V59.6H307.4z M311.5,60.3v4.7h-3.4v-4.7H311.5z M308.1,70.5v-4.7h3.4v4.7H308.1z" />
				</g>
				<g>
					<path d="M307.4,95.3h4.9V83.6h-4.9V95.3z M308.1,94.5v-4.7h3.4v4.7H308.1z M311.5,84.3v4.7h-3.4v-4.7H311.5z" />
				</g>
				<g>
					<path
						d="M325.3,46.6l-2.7-0.7v-4.2h-2.4l-4.8-2.1h-4.6v1.4h-2v-1.4h-4.6l-4.8,2.1h-2.4V46l-2.7,0.7v2l2.7,0.7v2.1h-0.8l-1.3-2.5
			l-0.7,0.3l1.5,3h1.2v0.6h-8.2v1.4h-2v-1.4h-9.7v1.4h-2v-1.4h-9.7v1.4h-2v-1.4h-8.2V34.3h1.2l1.4-3.5l0.1,0v-2l-2.7-0.7v-4.2H253
			l-4.8-2.1h-4.6v1.4h-2v-1.4h-4.6l-4.8,2.1h-2.4v4.2l-2.7,0.7v2l0.1,0l1.4,3.5h1.2v0.6h-8.2v1.4h-2v-1.4H210l0,1.4h-2v-1.4h-9.7
			v1.4h-2v-1.4h-8.2v-0.6h1.2l1.4-3.5l0.1,0v-2l-2.7-0.7V16.5h1.2l1.4-3.5l0.1,0v-2l-2.7-0.7V6.1h-4.4l-11-4.1h-8v1.4h-2V2h-8
			l-11,4.1h-4.4v4.2l-2.7,0.7v2l0.1,0l1.4,3.5h1.2v11.7l-2.7,0.7v2l0.1,0l1.4,3.5h1.2v0.6h-8.2v1.4h-2v-1.4h-9.7v1.4h-2v-1.4h-9.7
			v1.4h-2v-1.4h-8.2v-0.6h1.2l1.4-3.5l0.1,0v-2l-2.7-0.7v-4.2h-2.4l-4.8-2.1H86v1.4h-2v-1.4h-4.6l-4.8,2.1h-2.4v4.2l-2.7,0.7v2
			l0.1,0l1.4,3.5h1.2v18.4H64v1.4h-2v-1.4h-9.3c-0.4,0-0.4,0-0.4,1.4h-2v-1.4h-9.7v1.4h-2v-1.4h-8.2v-0.6h1.2l1.4-3.5l0.1,0v-2
			L30.5,46v-4.2h-2.4l-4.8-2.1h-4.6v1.4h-2v-1.4h-4.6l-4.8,2.1H5.1V46l-2.7,0.7v2l0.1,0l1.4,3.5h1.2v53.5l64.8,0l0,0h2.7v0h66.8h3.5
			h8.8h3.5h3.4H169h3.4h3.5h8.8h3.5H255v0h2.4h0.4l64.8,0V52.1h1.2l1.4-3.5l0.1,0V46.6z M321.8,45.9l-1.3,0v-3.4h1.3V45.9z
			 M315.7,40.7l4,1.7v3.3l-4-1.7V40.7z M311.6,41.8v-1.4h3.4v3.4h-10.2v-3.4h3.4v1.4H311.6z M299.9,42.4l4-1.7v3.3l-4,1.7V42.4z
			 M299.1,42.5v3.4h-1.3v-3.4H299.1z M295.1,48v-0.8l2.4-0.6h2.1l4.8-2.1h10.8l4.8,2.1h2.1l2.4,0.6V48l-2.4,0.6h-1.9l-4.8-2.1h-11.1
			l-4.8,2.1h-1.9L295.1,48z M297.9,53.5v-0.4v-3.7h1.3v50h-1.3V53.5z M299.9,49.2l4-1.7v51.8h-4V49.2z M304.7,47.3h10.2v57.5
			l-10.2,0V47.3z M315.7,47.5l4,1.7v55.6l-4,0V47.5z M262.8,53.5v1.4h3.5v-1.4h8.1v1.4h3.5v-1.4h8.1v1.4h3.5v-1.4h7.5v3.4h-41.7
			v-3.4H262.8z M198.4,93.2H193v-4h5.4V93.2z M193,93.9h5.4v5.4H193V93.9z M199.1,100.1h7.5h6.9h7.5h6.9h8.8v4.7h-45.8v-4.7h1.3
			H199.1z M212.8,93.2h-5.4v-4h5.4V93.2z M207.4,93.9h5.4v5.4h-5.4V93.9z M227.2,93.2h-5.4v-4h5.4V93.2z M221.8,93.9h5.4v5.4h-5.4
			V93.9z M230.7,35.7v-0.4v-3.7h1.3v67.8h-1.3V35.7z M236.8,99.3h-4V31.4l4-1.7V99.3z M237.5,29.5h10.2v75.3h-10.2V29.5z
			 M248.5,29.7l4,1.7v73.4h-4V29.7z M253.3,31.6h1.3v73.3h-1.3V31.6z M256.1,33.5h-0.7v-2.1l1.7-0.4L256.1,33.5z M254.6,28.1l-1.3,0
			v-3.4h1.3V28.1z M248.5,22.8l4,1.7v3.3l-4-1.7V22.8z M244.4,24v-1.4h3.4V26h-10.2v-3.4h3.4V24H244.4z M232.7,24.6l4-1.7v3.3
			l-4,1.7V24.6z M232,24.7V28h-1.3v-3.4H232z M227.9,29.4l2.4-0.6h2.1l4.8-2.1H248l4.8,2.1h2.1l2.4,0.6v0.8l-2.4,0.6H253l-4.8-2.1
			h-11.1l-4.8,2.1h-1.9l-2.4-0.6V29.4z M229.2,33.5l-1-2.5l1.7,0.4v2.1H229.2z M195.6,35.7V37h3.5v-1.4h8.1V37h3.5l0-1.4h8.1V37h3.5
			v-1.4h7.5V39h-41.7v-3.4H195.6z M188.1,81.5h-3.4v-1.3h3.4V81.5z M184,79.5v1.4h-8.1v-1.3h0.7v-2.1h2.3l10.4,1.9l-0.4,0.5v-0.5
			H184z M172.3,80.8h-17.1v-1.3h0.7v-2.1h15.7v2.1h0.7V80.8z M151.8,80.8h-8.1v-1.4h-4.9V80l-0.4-0.5l10.4-1.9h2.3v2.1h0.7V80.8z
			 M142.9,81.5h-3.4v-1.3h3.4V81.5z M151.9,77.5h3.4v1.3h-3.4V77.5z M172.4,77.5h3.4v1.3h-3.4V77.5z M189.5,78.7l-10.5-2l-2.4,0v0
			h-4.9v0l-15.7,0v0h-4.9v0l-2.4,0l-10.6,2V76l10.6-2.7h30.1l10.6,2.7V78.7z M140.2,35.7v-0.4v-3.7h3.4v42.3l-3.4,0.8V35.7z
			 M183.3,73.7l-2-0.5V63.1l-6.2-2.3v11.8h-2v-45l10.2,3.8V73.7z M180.6,67.6l-4.7-1.8v-4l4.7,1.8V67.6z M175.8,66.7l4.7,1.8V73
			l-1.6-0.4h-3.1V66.7z M172.3,72.6h-17.1V27.4h17.1V72.6z M154.5,72.6h-2V60.8l-6.2,2.3v10.1l-2,0.5V31.4l10.2-3.8V72.6z
			 M151.8,65.9l-4.7,1.8v-4l4.7-1.8V65.9z M147.1,68.5l4.7-1.8v5.9h-3l-1.7,0.4V68.5z M184.1,73.9V31.6h3.4v3.7v0.4v39.1L184.1,73.9
			z M188.9,33.5h-0.7v-2.1l1.7-0.4L188.9,33.5z M188.9,15.7h-0.7v-2.1l1.7-0.4L188.9,15.7z M187.4,10.2h-3.4V6.9h3.4V10.2z
			 M173.1,2.9l10.2,3.8v3.3l-10.2-3.8V2.9z M165.6,4.1V2.8h6.8v3.4h-17.1V2.8h6.8v1.4H165.6z M144.3,6.8l10.2-3.8v3.3l-10.2,3.8V6.8
			z M143.5,6.9v3.4h-3.4V6.9H143.5z M137.5,11.6l2.4-0.6h4.1l11-4.1h17.7l11,4.1h4.1l2.4,0.6v0.8l-2.4,0.6h-4l-11-4.1h-18l-11,4.1
			h-4l-2.4-0.6V11.6z M184.1,28V13.7h3.4V28H184.1z M140.2,13.7l3.4,0V28h-3.4V13.7z M144.3,13.6l10.2-3.8v14.3l-10.2,3.8V13.6z
			 M155.3,9.6h17.1v14.3h-17.1V9.6z M173.1,9.8l10.2,3.8v14.3l-10.2-3.8V9.8z M138.7,15.7l-1-2.5l1.7,0.4v2.1H138.7z M137.5,29.4
			l2.4-0.6h4.1l11-4.1h17.7l11,4.1h4.1l2.4,0.6v0.8l-2.4,0.6h-4l-11-4.1h-18l-11,4.1h-4l-2.4-0.6V29.4z M138.7,33.5l-1-2.5l1.7,0.4
			v2.1H138.7z M105.2,35.7V37h3.5v-1.4h8.1V37h3.5v-1.4h8.1V37h3.5v-1.4h7.5V39H97.7v-3.4H105.2z M105.8,93.2h-5.4v-4h5.4V93.2z
			 M100.4,93.9h5.4v5.4h-5.4V93.9z M106.6,100.1h7.5h6.9h7.5h6.9h1.3v4.7H90.8v-4.7h8.8H106.6z M120.2,93.2h-5.4v-4h5.4V93.2z
			 M114.8,93.9h5.4v5.4h-5.4V93.9z M134.6,93.2h-5.4v-4h5.4V93.2z M129.2,93.9h5.4v5.4h-5.4V93.9z M75.1,31.4l4-1.7v75.1h-4V31.4z
			 M79.9,29.5h10.2v75.3H79.9V29.5z M94.9,99.3h-4V29.7l4,1.7V99.3z M95.6,99.3V31.6h1.3v3.7v0.4v63.7H95.6z M98.4,33.5h-0.7v-2.1
			l1.7-0.4L98.4,33.5z M96.9,28.1l-1.3,0v-3.4h1.3V28.1z M90.8,22.8l4,1.7v3.3l-4-1.7V22.8z M86.7,24v-1.4h3.4V26H79.9v-3.4h3.4V24
			H86.7z M75.1,24.6l4-1.7v3.3l-4,1.7V24.6z M74.3,24.7V28H73v-3.4H74.3z M70.3,29.4l2.4-0.6h2.1l4.8-2.1h10.8l4.8,2.1h2.1l2.4,0.6
			v0.8l-2.4,0.6h-1.9l-4.8-2.1H79.4l-4.8,2.1h-1.9l-2.4-0.6V29.4z M71.5,33.5l-1-2.5l1.7,0.4v2.1H71.5z M38,53.5v1.4h3.5v-1.4h8.1
			v1.4h3.5v-0.4c0,0,0-0.6,0-1h8.1v1.4h3.5v-1.4h7.5v3.4H30.5v-3.4H38z M28.5,99.3v-50h1.3v3.7v0.4v45.8H28.5z M7.9,49.2l4-1.7v57.3
			l-4,0V49.2z M12.7,47.3h10.2v57.5l-10.2,0V47.3z M23.7,47.5l4,1.7v50.1h-4V47.5z M31.3,51.4h-0.7v-2.1l1.7-0.4L31.3,51.4z
			 M29.8,45.9l-1.3,0v-3.4h1.3V45.9z M23.7,40.7l4,1.7v3.3l-4-1.7V40.7z M19.6,41.8v-1.4h3.4v3.4H12.7v-3.4h3.4v1.4H19.6z M7.9,42.4
			l4-1.7v3.3l-4,1.7V42.4z M7.1,42.5v3.4H5.8v-3.4H7.1z M3.1,47.2l2.4-0.6h2.1l4.8-2.1h10.8l4.8,2.1h2.1l2.4,0.6V48l-2.4,0.6h-1.9
			l-4.8-2.1H12.2l-4.8,2.1H5.5L3.1,48V47.2z M4.4,51.4l-1-2.5l1.7,0.4v2.1H4.4z M5.8,49.4h1.3v55.4H5.8V49.4z M69.5,104.8l-45.8,0
			v-4.7h8.8h6.9h7.5h6.9h7.5h6.9h1.3V104.8z M38.7,93.2h-5.4v-4h5.4V93.2z M33.3,93.9h5.4v5.4h-5.4V93.9z M53.1,93.2h-5.4v-4h5.4
			V93.2z M47.7,93.9h5.4v5.4h-5.4V93.9z M67.5,93.2h-5.4v-4h5.4V93.2z M62.1,93.9h5.4v5.4h-5.4V93.9z M68.2,99.3v-11h-6.9v11h-7.5
			v-11h-6.9v11h-7.5v-11h-6.9v11h-2V57.6h41.7v47.2h-2v-5.5H68.2z M73,31.6h1.3v73.3H73V31.6z M137.5,104.8v-5.5h-2.1v-11h-6.9v11
			H121v-11h-6.9v11h-7.5v-11h-6.9v11h-2V39.8h41.7v35.1l-2.1,0.5v4.2l0.2,0l1.2,1.8v1h0.7v22.5H137.5z M140.2,104.8V82.3h2v22.5
			H140.2z M142.9,82.3h0.7v-0.7h2.7v23.2h-3.4V82.3z M147.1,104.8V81.6h4.7v23.2H147.1z M152.5,104.8V79.5h2v25.3H152.5z
			 M159.4,104.8V87.1h8.8v17.7H159.4z M169,104.8V86.3h-10.4v18.5h-3.4V81.6h17.1v23.2H169z M173.1,104.8V79.5h2v25.3H173.1z
			 M175.8,81.6h4.7v23.2h-4.7V81.6z M181.3,104.8V81.6h2.7v0.7h0.7v22.5H181.3z M185.4,104.8V82.3h2v22.5H185.4z M188.2,82.3h0.7v-1
			l1.2-1.8l0.2,0v-4.2l-2.1-0.5V39.8h41.7v59.6h-2v-11H221v11h-7.5v-11h-6.9v11h-7.5v-11h-6.9v11h-2.1v5.5h-2V82.3z M255.4,104.8
			V57.6h41.7v41.7h-2v-11h-6.9v11h-7.5v-11h-6.9v11h-7.5v-11h-6.9v11h-2.1v5.5H255.4z M294.3,93.2h-5.4v-4h5.4V93.2z M288.9,93.9
			h5.4v5.4h-5.4V93.9z M280,93.2h-5.4v-4h5.4V93.2z M274.5,93.9h5.4v5.4h-5.4V93.9z M265.6,93.2h-5.4v-4h5.4V93.2z M260.2,93.9h5.4
			v5.4h-5.4V93.9z M258.1,100.1h1.3h6.9h7.5h6.9h7.5h6.9h8.8v4.7l-45.8,0V100.1z M320.5,104.8V49.4h1.3v55.4H320.5z M323.3,51.4
			h-0.7v-2.1l1.7-0.4L323.3,51.4z"
					/>
				</g>
				<g>
					<rect x="259.8" y="81.5" width="35" height="0.8" />
				</g>
				<g>
					<rect
						x="146.5"
						y="53.1"
						transform="matrix(0.9364 -0.351 0.351 0.9364 -9.2543 55.8474)"
						width="5.9"
						height="0.8"
					/>
				</g>
				<g>
					<rect
						x="177.8"
						y="50.5"
						transform="matrix(0.3511 -0.9363 0.9363 0.3511 65.5858 201.5384)"
						width="0.8"
						height="5.9"
					/>
				</g>
				<g>
					<rect x="160.4" y="51.4" width="6.9" height="0.8" />
				</g>
				<g>
					<rect x="15.7" y="51.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="15.7" y="77.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="82.9" y="51.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="82.9" y="77.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="307.8" y="51.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="307.8" y="77.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="240.6" y="51.4" width="4.1" height="0.8" />
				</g>
				<g>
					<rect x="240.6" y="77.4" width="4.1" height="0.8" />
				</g>
				<g>
					<path d="M160.7,103.5h6.2v-2.8h-6.2V103.5z M161.4,101.5h4.7v1.3h-4.7V101.5z" />
				</g>
			</g>
		</svg>
	);
}