import zeroFirst from "../assets/photo_puzzle/0-1_b.jpg";
import oneFirst from "../assets/photo_puzzle/1-1_b.jpg";
import twoFirst from "../assets/photo_puzzle/2-1_b.jpg";
import threeFirst from "../assets/photo_puzzle/3-1_b.jpg";
import fourFirst from "../assets/photo_puzzle/4-1_b.jpg";
import fiveFirst from "../assets/photo_puzzle/5-1_b.jpg";
import sixFirst from "../assets/photo_puzzle/6-1_b.jpg";
import sevenFirst from "../assets/photo_puzzle/7-1_b.jpg";
import eightFirst from "../assets/photo_puzzle/8-1_b.jpg";
import nineFirst from "../assets/photo_puzzle/9-1_b.jpg";
import zeroSecond from "../assets/photo_puzzle/0-2_b.jpg";
import oneSecond from "../assets/photo_puzzle/1-2_b.jpg";
import twoSecond from "../assets/photo_puzzle/2-2_b.jpg";
import threeSecond from "../assets/photo_puzzle/3-2_b.jpg";
import fourSecond from "../assets/photo_puzzle/4-2_b.jpg";
import fiveSecond from "../assets/photo_puzzle/5-2_b.jpg";
import sixSecond from "../assets/photo_puzzle/6-2_b.jpg";
import sevenSecond from "../assets/photo_puzzle/7-2_b.jpg";
import eightSecond from "../assets/photo_puzzle/8-2_b.jpg";
import nineSecond from "../assets/photo_puzzle/9-2_b.jpg";

import { useState, useEffect, useRef, useCallback } from 'react';
import CountdownTimer from "../components/timer";

/* Dear Puzzle Solver, viewing these hidden routes is not the intended way to solve the puzzle. 
If you view these hidden pages, you will likely spoil the solutions. If you wish to solve the puzzle the intended 
way, take a close look at the save the date picture we sent, as that will lead you through to these pages organically.*/
export default function PhotoPuzzlePage() {
    const images = [zeroFirst, oneFirst, twoFirst, threeFirst, fourFirst, fiveFirst, sixFirst, sevenFirst, eightFirst, nineFirst, nineSecond, eightSecond, sevenSecond, sixSecond, fiveSecond, fourSecond, threeSecond, twoSecond, oneSecond, zeroSecond];
    const PAUSE_TEXT = "||";
    const PLAY_TEXT = "▶";
    const maxTimerSeconds = 9;

    const [activeImageIdx, setActiveImageIdx] = useState(0);
    const [secondsLeft, setSecondsLeft] = useState(maxTimerSeconds);
    const [buttonText, setButtonText] = useState(PAUSE_TEXT);

    const timer = useRef(null);

    const clickBack = () => {
        let idx = activeImageIdx - 1;
        if (idx < 0) {
            idx = images.length - 1;
        }
        setActiveImageIdx(idx);
        setSecondsLeft(maxTimerSeconds);
        setButtonText(PAUSE_TEXT);
    }

    const clickForward = useCallback(() => {
        let idx = activeImageIdx + 1;
        if (idx >= images.length) {
            idx = 0;
        }
        setActiveImageIdx(idx);
        setSecondsLeft(maxTimerSeconds);
        setButtonText(PAUSE_TEXT);
    }, [activeImageIdx, images.length]);

    const pauseButtonClicked = () => {
        if (buttonText === PAUSE_TEXT){
            clearInterval(timer.current); 
            setButtonText(PLAY_TEXT);
            return;
        }

        if(buttonText === PLAY_TEXT){
            timer.current = setInterval(() => {
                setSecondsLeft(secondsLeft - 1);
                if (secondsLeft <= 0) {
                    clickForward();
                    setSecondsLeft(maxTimerSeconds);
                }
            }, 1000);
            setButtonText(PAUSE_TEXT);
        }
    };

    useEffect(() => {
        // useRef value stored in .current property
        timer.current = setInterval(() => {
            setSecondsLeft(secondsLeft - 1);
            if (secondsLeft <= 0) {
                clickForward();
                setSecondsLeft(maxTimerSeconds);
            }
        }, 1000);

        return () => {
            clearInterval(timer.current);
        };
    }, [secondsLeft, clickForward]);

    const getCurrentImage = () => {
        return images[activeImageIdx];
    }

    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
                <div className="row-top row-bottom">
                    <div className="text-container">
                        <h3 className="text-italic">A textceptional couple</h3>
                        <p className="text-lower">We're excited to be in this position as a couple</p>
                    </div>
                </div>
                <div className="image-container">
                    <img src={getCurrentImage()} alt="puzzle"></img>
                </div>
                <div className="puzzle-counter text-center row-top row-bottom">
                    <button className="cta" onClick={clickBack}>
                        &lt;
                    </button>
                    <div>
                        <div>
                            <CountdownTimer secondsLeft={secondsLeft} />
                        </div>
                        <div>
                            <button className="cta" onClick={pauseButtonClicked}>
                                {buttonText}
                            </button>
                        </div>
                    </div>
                    <button className="cta" onClick={clickForward}>
                        &gt;
                    </button>
                </div>
            </div>
        </main>
    );
}