import { app } from "../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(app);

const validateOtp = httpsCallable(functions, 'validateOtp');
const createMemory = httpsCallable(functions, 'createMemory');
const getParty = httpsCallable(functions, 'getParty');
const updateParty = httpsCallable(functions, 'updateParty');

export const ApiService = {
    async validateOtp(code) {
        return validateOtp({ otp: code })
            .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                const data = result.data;
                return {status: data.status, link: data.link}
            })
            .catch((error) => {
                console.log("Got an error");
                console.error(error.message);
                console.error(error.details);
            });
    },

    async getParty(name){
        return getParty({ name: name })
        .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            return {id: data.id, name: data.name, status: data.status, features: data.features, members: data.members}
        })
        .catch((error) => {
            console.log("Got an error");
            console.error(error.message);
            console.error(error.details);
        });
    },

    async updateParty(party){
        return updateParty(party)
        .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            return {id: data.id, name: data.name, status: data.status, features: data.features, members: data.members}
        })
        .catch((error) => {
            console.log("Got an error");
            console.error(error.message);
            console.error(error.details);
        });
    },


    async createMemory(memory) {
        return createMemory(memory)
        .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            return {id: data.id}
        })
        .catch((error) => {
            console.log("Got an error");
            console.error(error.message);
            console.error(error.details);
        });
    },
};


const fakePartiesTable = [
    {id:"1", name:"Bob and Linda Bobberson", members: [{name: "Bob", rsvp_status:"", question_status:""}, {name: "Linda", rsvp_status:"", question_status:""}], features: []},
    {id:"2", name:"Harry and Ginny Potter", members: [{name: "Harry", rsvp_status:"", question_status:""}, {name: "Ginny", rsvp_status:"", question_status:""}], features: []},
    {id:"3", name:"Dork McLoner", members: [{name: "Dork", rsvp_status:"", question_status:""}], features: ["gotcha"]},
];

export const FakeApiService = {
    async validateOtp(code) {
        if (code === "123456") {
            return { status: "correct", link: "/photo-puzzle" }
        }
        return { status: "incorrect" };
    },

    async getParty(name){
        for(const entry of fakePartiesTable){
            if (entry.name.toUpperCase() === name.toUpperCase()){
                return entry;
            }
        }
        return {};
    },

    async updateParty(party){
        if(!party || !party.id){
            return {error: "Invalid request"};
        }
        for(const entry of fakePartiesTable){
            if (entry.id === party.id){
                entry.members = party.members;
                return entry;
            }
        }
        return {status: "COMPLETE"};
    },

    async createMemory(memory){
        if(!memory || !memory.name || !memory.memory){
            return {error: "Invalid request"};
        }
        return {id: "abc"};
    }
};