import { useState } from 'react';


export default function TextOptInPage() {
    const [submitComplete, setSubmitComplete] = useState(false);

    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
				<div className="row-top row-bottom">
					<div className="text-container">
						<h2 className="text-italic">SMS Opt-In</h2>
						<p className="text-lower">
							This is the SMS opt-in page for the pre-wedding puzzle. Guests are invited to provide their
                            phone number for SMS opt-in. One of the puzzles will require users to text a Twilio phone number.
                            By providing your number, you agree to receive text messages with puzzle clues.
						</p>
                        {(!submitComplete ? <div><input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"></input><button className="cta" onClick={() => setSubmitComplete(true)}>Opt In to SMS</button></div> : <div />)}
                        {(submitComplete ? <div >Thanks for opting in!</div> : <div />)}
					</div>
				</div>
			</div>
        </main>
    );
}