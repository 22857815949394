export default function GiftsPage() {
    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
                <div className="row-bottom">
                    <div className="text-container">
                        <h2 className="text-italic">Gifts</h2>
                        <p className="text-lower">
                            The best gift you can give us is your smiling face on our dance floor.
                        </p>
                        <br></br>
                        <p className="text-lower">
                            For those who don't dance, don't smile, or are otherwise adamant about giving a gift, 
                            we would be both honored and honoured to have you donate to our honeymoon fund.
                        </p>
                        <br></br>
                        <a className="cta no-line" href="https://www.paypal.com/paypalme/FiMMcG">Contribute</a>
                    </div>
                </div>
            </div>
        </main>
    );
}