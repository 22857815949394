import LineSVG from "../assets/svg/line";

export default function VenuePage() {
    return (
        <main className="line-bottom">
            <div className="image-container">
                <img src="/images/fasque_small.jpeg" alt="" />
            </div>
            <div className="main-inner text-center">
                <div className="row-top row-bottom">
                    <div className="text-container">
                        <h2 className="text-italic">Fasque House</h2>
                        <p className="text-lower">
                            We will be getting married in a beautiful house in Aberdeenshire,
                            not far from where Fiona grew up.
                        </p>
                        <p className="text-lower">
                            The name Fasque comes from the Gaelic word <i>fasgadh</i>, meaning "safety", or "dwelling place".
                            For reasons of potential repetition, "house" was never officially added to the name.
                            But now that nobody remembers Gaelic, it's okay to call it “house house” if you want.
                        </p>
                    </div>
                </div>
                <LineSVG />
                <div className="row-top row-bottom">
                    <div className="text-container">
                        <h2>Early History</h2>
                        <p className="text-lower">
                            There used to be a house (the original Fasque) that was owned by the Ramsays of Balmain.
                            Various Ramsays held baronet titles, which is basically a hereditary title given by the Crown
                            in order to raise money from rich families. The baronet position, in its modern form, was created by
                            James VI (James I for our English friends).
                        </p>
                        <p className="text-lower">
                            The original Fasque existed from sometime in the early 1700s until the 1790s, when it was deemed 
                            so damp and gross that they tore it down.
                            A new house (the current one) was completed in 1809. Probably the Ramsays lived somewhere in
                            the twenty intervening years. Likely it was a place less mouldy than their previous home.
                        </p>
                        <p className="text-lower">
                            Fasque remained the property of the Ramsays for several generations. In 1829, Alexander Ramsay
                            had to sell it, due to the fact that it's pretty pricey to own a castle while not having an actual job.
                            The house was sold to John Gladstone, a Scottish merchant, for £80,000.
                            This is roughly the cost of a single window in a modern London flat.
                        </p>
                        <br></br>
                        <h2>The Gladstone Years</h2>
                        <p className="text-lower">
                            John Gladstone made buckets of money in international trade. This is the polite way of
                            saying that he owned a lot of slaves, particularly in Bermuda. In 1833, when the UK
                            abolished slavery, the government paid off slaveholders, that way the country wouldn't have
                            to fight a war over the whole mess. Old Johnny Gladstone, who was already fabulously wealthy, was paid more money than any
                            other single plantation owner in the UK. The multi-million-pound payment was made in order to free Gladstone's 2,508 surviving slaves.
                            John Gladstone lived at Fasque for the remainder of his life.
                        </p>
                        <p className="text-lower">
                            After Gladstone's death, the eldest son of the family, Thomas, took ownership of the house.
                            Thomas was a middling politician whose greatest claim to fame is that his little brother,
                            William Ewart Gladstone, became the Prime Minister of the UK four non-consecutive times.
                            William served in Britain's highest role for a total of 12 years, spread between 1868 and 1894.
                            Many historians consider William Gladstone to be one of the greatest Prime Ministers of all time.
                        </p>
                        <br></br>
                        <h2>A Brief Aside on Famous Prime Minister William Gladstone</h2>
                        <p className="text-lower">
                            William Gladstone was a Liberal politician who shaped many facets of Victorian life. He was
                            beloved by the working class, earning him the nickname “The People's William”, possibly because
                            “William the Great” was already taken. One of the PM's top achievements was that he gave more
                            men the right to vote. By enfranchising the less-than-wealthy, Gladstone allowed nearly anyone to partake in democracy,
                            as long as they still had some money and weren't women.
                        </p>
                        <p className="text-lower">
                            Gladstone also successfully navigated the <i>Alabama</i> Claims crisis, a heated, multi-year dispute with
                            the United States. The issue was that, during the American Civil War, Britain sneakily
                            provided the Confederate States with war ships. Britain claimed that these were
                            not war ships, but “merchant vessels”. They bolstered this claim by painting pictures of Stephen Merchant
                            on the cannons. The most successful of these boats, the <i>Alabama</i>, was used by the Confederacy
                            to capture fifty-eight Union ships, before finally being sunk - two years into its obviously peaceful voyage.
                        </p>
                        <p className="text-lower">
                            When the American Civil War ended, the United States felt a little steamy about the whole
                            “Britain gave the Confederates a Navy, and That Kinda Sucked” thing. The US Government sought
                            damages based on the argument that the British Government had inadequately followed its own neutrality laws.
                            Some prominent Americans suggested that Britain offer up Canada as compensation.
                            Such proposals were not taken seriously by British statesmen, but you can tell things are getting hot when Americans
                            start asking for Canada.
                        </p>
                        <p className="text-lower">
                            Into this mess stepped William Gladstone, who eventually brokered a deal in which Canada got to
                            stay Canada, the US didn't have to introduce “Really North Dakota”, and the UK paid $15 million,
                            a token gesture. The peaceful resolution of these claims set an important precedent for
                            solving serious international disputes and laid the foundation for greatly improved relations
                            between Britain and the United States. Some historians have drawn a direct line between this improved British-American
                            relationship and the strong bond formed between Fiona and Matt, national
                            treasures of the UK and US, respectively.
                        </p>
                        <br></br>
                        <h2>Entering Modern Day</h2>
                        <p className="text-lower">
                            Bringing it back to Fasque, William Gladstone sometimes visited his increasingly
                            estranged brother, Thomas, at the house. While there, William engaged in his thrilling hobbies of
                            “walking” and “tree-felling” - the “Netflix” and “chill” of the mid-1800s. William also kept
                            his collection of 5,000 books at Fasque, where they remained until William was given his own
                            castle. At this point, the irresponsibly large collection was moved, probably by a group
                            of servants who later didn't like to read.
                        </p>
                        <p className="text-lower">
                            Fasque was passed down through the generations. It was briefly owned, in the 1970s, by Sir William Gladstone,
                            great-grandson of the aforementioned Prime Minister. The younger William Gladstone became a famed Chief Scout.
                            The honorific of Chief Scout would eventually pass to Bear Grylls, though unfortunately Bear has never
                            (yet) owned Fasque.
                        </p>
                        <p className="text-lower">
                            In 2010, Fasque was bought by the private preservation society <i>Fasque House Properties Ltd</i>, and a
                            complete restoration of the house began. The house is today used as a wedding venue and for corporate
                            events. If the corporate event is about a merger, that sorta counts as both.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
}