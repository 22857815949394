import Toggle from "../components/toggle";

export default function FAQsPage() {
    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
                <div className="row-bottom">
                    <div className="text-container">
                        <h2 className="text-italic">FAQs</h2>
                        <Toggle title="What's the dress code?" blurb="General fancy. Most people will be in nice suits, kilts, or dresses. No top hats or tails needed." />
                        <Toggle title="What's the food situation?" blurb="There will be light snacks during the cocktail hour followed by a full meal, 
                        the details of which will be revealed later. We'll work with guests to ensure all dietary requirements are met." />
                        <Toggle title="Can my small child join?" blurb="In general we ask that you take the night off and leave your 
                        infant children to fend for themselves. If you have questions, or if it's unrealistic for you to travel alone, reach out to us to discuss options." />
                        <Toggle title="Will there be parking at the venue?" blurb="Yes, there's plenty of parking at the castle. Cars or horses welcome." />
                        <Toggle title="Can I bring a date, friend, and/or pet?" blurb="No, but nice try" />
                        <Toggle title="What's ceilidh dancing, and will I have to do it?" blurb="A ceilidh is a traditional Scottish barn dance, in which large groups of people have 
                        insane amounts of fun. All ages can participate, so to answer your second question: yes. We'll walk everyone through the steps, but if you want to prepare in advance,
                        you can have a look at two of the most popular dance routines:">
                            <div className="toggle-row line-top">
                                <p className="text-lower row-item">
                                    Gay Gordons
                                </p>
                                <div className="row-item">
                                    <p className="text-lower">
                                        You'll start in pairs. You and your partner will stay together the entire time.
                                    </p>
                                    <a
                                        className="item-link"
                                        href="https://www.youtube.com/watch?v=7YIqMg8jnpE&ab_channel=USCN"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Learn More
                                    </a>
                                </div>
                            </div>
                            <div className="toggle-row line-top">
                                <p className="text-lower row-item">
                                    Dashing White Sergeant
                                </p>
                                <div className="row-item">
                                    <p className="text-lower">
                                        You'll start by forming two groups of three. Your three will stay together for the entire dance, but you'll rotate through
                                        to pair with every other set of three.
                                    </p>
                                    <a
                                        className="item-link"
                                        href="https://www.youtube.com/watch?v=3m_ZuXddYz0&ab_channel=LondonReels"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </Toggle>
                        <Toggle title="Where can I rent a kilt?" blurb="There will be many people at this beautiful wedding donning kilts. If you'd like to have the true
                        Scottish experience, you can rent a kilt. Just keep in mind that you'll need to return the kilt after the weekend, so you'll need to take 
                        this into considerations when planning your post-event travel. Typical rentals come with the kilt, a shirt, tie, jacket, shoes, socks, knife (sgian-dubh), and bag (sporran). 
                        If you need help choosing a tartan pattern, we're happy to help.">
                            <div className="toggle-row line-top">
                                <p className="text-lower row-item">
                                    Rent in Aberdeen
                                </p>
                                <div className="row-item">
                                    <a
                                        className="item-link"
                                        href="https://www.mccalls.co.uk/ "
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        McCalls
                                    </a>
                                </div>
                            </div>
                            <div className="toggle-row line-top">
                                <p className="text-lower row-item">
                                    Rent in Edinburgh
                                </p>
                                <div className="row-item">
                                    <a
                                        className="item-link"
                                        href="https://www.mccalls.co.uk/collection/kilt-hire-edinburgh/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        McCalls Edinburgh
                                    </a>
                                </div>
                            </div>
                        </Toggle>
                        <Toggle title="What will the weather be like in Scotland in late May?" blurb="Honestly, your guess is as good as ours. There probably won't be snow, and it would be surprising if there's a sandstorm. 
                        We're hoping that it will be dry and around 14C/57F. Rain is entirely possible." />
                        <Toggle title="Will the ceremony be indoors or outdoors?" blurb="We will be getting married indoors, in the Fasque Library. For more context, see the 'weather' question above." />
                        <Toggle title="How should I get to the venue?" blurb="It depends a bit on where you're coming from. Check out the Accomodation page for details." />
                        <Toggle title="What is the detailed itinerary for the weekend?" blurb="We'll share more details as we finalise them, in the many months remaining before the wedding." />
                        <Toggle title="Is there some kind of ridiculous pre-wedding puzzle hunt that I've wandered into?" blurb="Well there's definitely something weird with that 'hidden carvings in wood' nonsense from the RSVP form. You could consider having a thorough look at the Save the Date picture we sent you..." />
                        <Toggle title="I have more questions, what do I do?" blurb="Firstly, don't panic. Feel free to reach out to Matt or Fiona directly, we'll get you sorted." />
                    </div>
                </div>
            </div>
        </main>
    );
}