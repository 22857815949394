import OtpInput from 'react-otp-input';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import LoadingWheel from '../components/loadingWheel';

import { ApiService } from '../services/apiService';

export default function OTPPage() {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const otpInputStyle = {
        width: "3em",
    }

    const onChange = (val) => {
        // Strip any non-number values from the input
        val = val.replace(/\D/g, '');
        setOtp(val);
        if (val.length >= 6) {
            setIsLoading(true);

            ApiService.validateOtp(val).then(result => {
                setIsLoading(false);
                setErrorMessage();
                if (result && result.status && result.status === "correct" && result.link) {
                    navigate(result.link);
                } else {
                    setErrorMessage("Incorrect!");
                    setOtp();
                }
            });
        }
    }

    const clearButtonClicked = () => {
        setOtp('');
        var inputs = document.getElementsByTagName('input');
        if (inputs && inputs[0]) {
            inputs[0].focus();
        }
    }

    return (
		<main className="line-top line-bottom">
			<div className="main-inner text-center">
				<div className="row-top row-bottom">
					<div className="text-container">
						<h2 className="text-italic">One Time Code</h2>
						<p>
							Welcome to the Fiona and Matt wedding puzzle. If you wish to continue, please enter the one-time code
							that was sent to you. You definitely have it if you've made it this far.
						</p>
					</div>
				</div>
			</div>

			{!isLoading ? (
				<div className="puzzle-container">
					<OtpInput
						id="otp-input"
						value={otp}
						onChange={onChange}
						numInputs={6}
						renderSeparator={<span>ο</span>}
						shouldAutoFocus={true}
						inputStyle={otpInputStyle}
						renderInput={(props) => <input {...props} />}
						containerStyle={{
							justifyContent: "center",
							marginBottom: "1rem",
							gap: "2px",
						}}
					/>
					<div className="main-inner text-center row-top row-bottom">
						{errorMessage ? (
							<div className="puzzle-error">{errorMessage}</div>
						) : (
							<div />
						)}
						<button className="cta" onClick={clearButtonClicked}>
							Clear
						</button>
					</div>
				</div>
			) : (
				<div />
			)}
			{isLoading ? <div className="main-inner text-center row-top row-bottom"><LoadingWheel /></div> : <div />}
		</main>
	);
}