import Toggle from "../components/toggle";

export default function EdinburghEatsToggle() {
    return (
        <Toggle title="Places to eat and drink in Edinburgh" blurb="There's so much to do in Edinburgh, and one of the most important things you can do is drink whisky and eat a full Scottish breakfast.">
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Johnnie Walker Experience
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        This massive institution offers a personalised whisky experience where you can explore the flavours of Scotland in the heart of Edinburgh.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.johnniewalker.com/en-us/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Chaophraya
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Delicious Thai restaurant with views of Edinburgh Castle. You'll need to book in advance and request a castle view.
                    </p>
                    <a
                        className="item-link"
                        href="https://chaophraya.co.uk/thai-restaurant/edinburgh"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Cold Town House Rooftop Bar
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        A great place for a beer and some pub food. When you book, be sure to request a table on the rooftop.
                    </p>
                    <a
                        className="item-link"
                        href="https://coldtownhouse.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Timberyard
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Fancy food for fancy people.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.timberyard.co/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Dishoom
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        A famous Indian food chain. There are a few in London and one in Edinburgh. It's the best Indian food outside of India.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.dishoom.com/edinburgh/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Kings Wark Pub (Leith)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Leith is on the coast, a little ways from the heart of the city. But if you're over in that direction, this is our favourite pub! It's cozy and amazing.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.thekingswarkpub.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Stockbridge Market
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        If you are in Edinburgh on a Sunday, check out the farmer's market, open every Sunday all year round 10am to 4pm.
                    </p>
                    <a
                        className="item-link"
                        href="http://www.stockbridgemarket.com/stockbridge.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Breakfast spots
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        You definitely need to try a full Scottish breakfast! Places tend to be small, so book if you can.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.timeout.com/edinburgh/restaurants/best-breakfasts-and-brunches-in-edinburgh"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
        </Toggle>
    );
}