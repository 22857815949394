import EdinburghEatsToggle from "../components/edinburghEatsToggle";
import EdinburghThingsToggle from "../components/edinburghThingsToggle";
import LondonEatsToggle from "../components/londonEatsToggle";
import LondonThingsToggle from "../components/londonThingsToggle";

export default function UKPage() {
    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
                <div className="row-top row-bottom">
                    <div className="text-container">
                        <h2 className="text-italic">Planning a UK Trip</h2>
                        <p className="text-lower">
                            If your schedule allows, we recommend coming to the UK at least a few days early.
                            This will allow you to overcome any jet lag, and also gives you the chance to see some sights.
                        </p>
                        <br></br>
                        <p className="text-lower">
                            American citizens will need a passport to fly, but no visa is required.
                        </p>
                        <br></br>
                        <p className="text-lower">
                            We recommend that you fly to either London Heathrow (LHR) or Edinburgh (EDI). Both cities
                            have a lot to offer.
                        </p>
                    </div>
                </div>
            </div>
            <div className="line-top">
                <EdinburghThingsToggle />
                <EdinburghEatsToggle />
                <LondonThingsToggle />
                <LondonEatsToggle />
            </div>
        </main>
    );
}