export default function Toggle(props) {
    function toggleToggle(e) {
        const toggleParent = e.target.closest(".toggle");
        toggleParent.classList.toggle("open");
    }

    return (
        <div className="toggle-container">
            <div className="toggle">
                <button className="toggle-title" onClick={toggleToggle}>
                    {props.title}
                </button>
                <div className="toggle-content">
                    <div className="text-container">
                        <p className="text-lower">
                            {props.blurb}
                        </p>
                    </div>
                    <div className="toggle-table">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}