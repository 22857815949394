import NavigationMenu from "../components/navigationMenu";
import CastleSVG from "../assets/svg/castle";
import LineSVG from "../assets/svg/line";

export default function Header() {
	return (
		<header className="main-container line-top row-bottom">
			<div className="main-inner">
				<div className="text-container row-top row-bottom text-center">
					<CastleSVG />
					<h1 className="text-italic">Fiona and Matt</h1>
					<LineSVG />
				</div>
				<NavigationMenu />
			</div>
		</header>
	);
}