import Toggle from "../components/toggle";

export default function EdinburghThingsToggle() {
    return (
        <Toggle title="Things to do in Edinburgh" blurb="Edinburgh is Scotland's capital city. It has a medieval Old Town
                            and elegant Georgian New Town. There is plenty to do in Edinburgh
                            itself, but it is also a good base for day trips to other parts of
                            Scotland. The city itself is small and there are plenty of buses
                            to get around.">
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Edinburgh Castle
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Stands on Castle Rock in the heart of the city. This is a legit, beautiful castle that you can tour and, if you're feeling frisky, storm. (Please don't actually storm the castle)
                    </p>
                    <a
                        className="item-link"
                        href="https://www.edinburghcastle.scot/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    The Royal Mile and Grassmarket
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Connecting the magnificent Edinburgh Castle with the Palace of
                        Holyrood, this avenue is peppered with historical sites like St Giles' Cathedral and some of the best eating and drinking spots in the city. 
                        For a glimpse of recent history, be sure to visit the ultra-modern Scottish Parliament. 
                    </p>
                    <a
                        className="item-link"
                        href="https://www.visitscotland.com/info/towns-villages/royal-mile-and-grassmarket-p918401"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Arthur's Seat (and Holyrood Park)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        An extinct volcano that's a short walk from Edinburgh's Royal Mile. You can walk up Arthur's Seat (a must-do for Edinburgh visitors). 
                        You can start from the bottom, or drive most of the way up. It takes less than an hour, and provides fantastic views of the city. 
                    </p>
                    <a
                        className="item-link"
                        href="https://www.visitscotland.com/info/see-do/holyrood-park-and-arthurs-seat-p914341"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Carlton Hill and National Monument
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Marked as a UNESCO World Heritage Site, Calton Hill has some of the city's best views and, if you get up early, the best sunrises.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.visitscotland.com/info/see-do/calton-hill-and-national-monument-p1419631"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    National Museum of Scotland
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        A free museum of Scottish history and culture. This is one of Matt's favorite places in the city.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.nms.ac.uk/national-museum-of-scotland/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    National Portrait Gallery
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        I think you can figure this one out.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.nationalgalleries.org/visit/scottish-national-portrait-gallery"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Edinburgh University
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        This is where Fiona studied. The campus is host to beautiful buildings with a true Harry Potter feel.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.ed.ac.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Harry Potter Graveyard
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        J.K. Rowling famously wrote the first Harry Potter book in a few cafes in Edinburgh. Sadly, the most famous cafe, The Elephant House, was badly damaged in a fire (incendio charm, damn you).
                        It might be open again by 2025, so keep an eye out.
                        However, there is still a graveyard where Rowling used to hang out for inspiration. You can find headstones with names of many famous Harry Potter characters.
                    </p>
                    <a
                        className="item-link"
                        href="https://secret-edinburgh.com/greyfriars-kirkyard/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Harry Potter Walking Tour
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        For the full Potterhead experience, you can join one of the free walking tours of the city. The tours fill up fast, so you'll need to book well in advance.
                        If your group is larger than 8, we recommend booking a private tour (for a little dolla). It's a better experience anyway.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.pottertrail.com/public-tours"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
        </Toggle>
    );
}