import { useState } from 'react';

export default function RadioQuestion(props) {

    const [response, setResponse] = useState('');
    const YES_VALUE = "yes";
    const NO_VALUE = "no";

    return (<div className="rsvp-row">
        <div className="rsvp-question">
            {props.question}
        </div>
        <div className="rsvp-answer">
            <label htmlFor="yes">
                <input
                    type="radio"
                    value={YES_VALUE}
                    checked={response === YES_VALUE} onChange={e => {
                        e.target.checked ? setResponse(YES_VALUE) : setResponse('');
                        props.onChange({idx: props.idx, value:YES_VALUE});
                    }}
                />
                Yes
            </label>

            <label htmlFor="no">
                <input
                    type="radio"
                    value={NO_VALUE}
                    checked={response === NO_VALUE} onChange={e => {
                        e.target.checked ? setResponse(NO_VALUE) : setResponse(''); 
                        props.onChange({idx: props.idx, value:NO_VALUE});
                    }}
                />
                No
            </label>
        </div>
    </div>);

}