export default function Line() {
	return (
		<svg
			version="1.1"
			id="line-svg"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 180.8 17.2"
			className="illustration"
		>
			<g>
				<g>
					<g>
						<rect x="2.4" y="8.2" width="77.4" height="0.8" />
					</g>
					<g>
						<path d="M85.1,14.5l-5.9-5.9l5.9-5.9L91,8.6L85.1,14.5z M80.3,8.6l4.8,4.8L90,8.6l-4.8-4.8L80.3,8.6z" />
					</g>
				</g>
				<g>
					<g>
						<rect x="101" y="8.2" width="77.4" height="0.8" />
					</g>
					<g>
						<path d="M95.9,14.5L90,8.6l5.9-5.9l5.9,5.9L95.9,14.5z M91,8.6l4.8,4.8l4.8-4.8l-4.8-4.8L91,8.6z" />
					</g>
				</g>
			</g>
		</svg>
	);
}