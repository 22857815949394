export default function STDPage() {
    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
                <div className="row-top row-bottom">
                    <div className="text-container">
                        <h2 className="text-italic">Save the Date</h2>
                        <div className="image-container">
                            <img src="/images/std_small.jpeg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}