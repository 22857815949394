import Toggle from "../components/toggle";

export default function LondonThingsToggle() {
    return (
        <Toggle title="Things to do in London" blurb="London is the capital of England, and a city of global renown.">
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    The Big Sights
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Westminster Abbey, Parliament Square, Buckingham Palace, Tower of London, Big Ben...the list goes on and on. You can walk and see a lot, but you can also
                        do a bus tour. We've never personally done the bus before, but it's an excellent option for those who want to conserve their energy for the late-night partying.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.bigbustours.com/en/london/london-bus-tours"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    British Museum (free, some paid exhibits)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Want to see the Rosetta Stone? Want to see literally forty million other things? Check out the British Museum. Matt has a membership
                        and can get 1 person into the paid exhibits for free, so start bribing. Also, just across the street is the London Review of Books Store,
                        which is Matt's favorite bookstore in London, and worth a browse.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.britishmuseum.org/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Tate Modern (free, some paid exhibits)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        The Tate Modern has more modern art then you will ever be able to see in a single visit. Matt has been many times and still gets lost.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.tate.org.uk/visit/tate-modern"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    V&A Museum (free, some paid exhibits)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        The building itself is one of the most stunning buildings in London, and the tea rooms are an amazing place to stop for a rest and snack.
                        The museum itself is one of Matt's favorites. Sometimes he just goes there to read.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.vam.ac.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Churchill War Rooms (~$40/£32)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        The War Rooms are part of the Imperial War Museum, though they are in a separate location. The War Rooms are genuinely fun. You can see a different side of WWII.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.iwm.org.uk/visits/churchill-war-rooms"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Borough Market and/or Camden Market
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Two really great open-air markets, with lots of food, drinks, trinkets, art, and more. 
                        Borough Market is more food-oriented while Camden Market is more trendy and hip. Both are great. 
                        If you were to pick only one, we'd go Borough.
                    </p>
                    <a
                        className="item-link"
                        href="https://boroughmarket.org.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        More Borough Market
                    </a>
                    <a
                        className="item-link"
                        href="https://www.camdenmarket.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        More Camden Market
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Harry Potter Studios ($70/£55)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Tour the sets from the actual Harry Potter movies. It's a must-see if you once obsessed about midnight book releases. 
                        You can probably spend a good chunk of the day here.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.wbstudiotour.co.uk/experience/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    West End Shows
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Every day there are many shows to see. A lot of shows are only ever on in London. Don't make the mistake of thinking 
                        that you'll catch it when it's in NYC.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.londontheatredirect.com/all-events"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    The Globe (Shakespeare)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Visit the "original" Shakespeare theatre. There's always something good on. 
                        Many tickets are outdoor standing only, so keep that in mind. Tickets are also absurdly cheap, 
                        so keep that in mind too.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.shakespearesglobe.com/whats-on/#event"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Abbey Road
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        You might know The Beatles as some of the most famous pedestrians of all time. You, too, can hold up traffic for an iconic picture. 
                        The website literally has a live webcam feed of the crosswalk, and historical views by hour, so you can plan your visit for maximum carnage.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.abbeyroad.com/crossing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Flight Club
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        There are a bunch of pubs that offer "Flight Club", which is basically group darts. It's truly fun. If you have a group of 4-8, this is 
                        a perfect way to spend a chill few hours.
                    </p>
                    <a
                        className="item-link"
                        href="https://flightclubdarts.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Bletchley Park
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        If you've seen the movie "The Imitation Game", or if you know about Alan Turing through other avenues, you might love this place. It's 
                        about a 1 hour journey by train, but it's the perfect day trip. This is where some of the greatest mathematicians of all time broke the German 
                        Enigma code that helped the Allies win WWII. The facilities are now a museum sponsored by Google, and it's in the top 3 best historical sites Matt 
                        has ever been to. He literally spent his 30th birthday here, because it's that great.
                    </p>
                    <a
                        className="item-link"
                        href="https://bletchleypark.org.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
        </Toggle>
    );
}