import { useNavigate } from "react-router-dom";

export default function NavigationMenu() {
    const navigate = useNavigate();

    const handleNavClick = (e) => {
        if (!e || !e.target || !e.target.id) {
            return;
        }
        switch (e.target.id) {
            case "home":
                navigate("/");
                break;
            case "venue":
                navigate("/venue");
                break;
            case "gifts":
                navigate("/gifts");
                break;
            case "faqs":
                navigate("/faqs");
                break;
            case "accomm":
                navigate("/accomm");
                break;
            case "uktrip":
                navigate("/uk-trip");
                break;
            default:
                break;
        }
    };
    return (
        <div className="header-container">
            <div className="header-inner">
                <button
                    key="1"
                    id="home"
                    onClick={handleNavClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                    className="nav-item"
                >
                    Home
                </button>
                <button
                    key="2"
                    id="venue"
                    onClick={handleNavClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                    className="nav-item"
                >
                    Venue
                </button>
                <button
                    key="5"
                    id="accomm"
                    onClick={handleNavClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                    className="nav-item"
                >
                    Accommodation
                </button>
                <button
                    key="6"
                    id="uktrip"
                    onClick={handleNavClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                    className="nav-item"
                >
                    UK Trip
                </button>
                <button
                    key="4"
                    id="gifts"
                    onClick={handleNavClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                    className="nav-item"
                >
                    Gift registry
                </button>
                <button
                    key="3"
                    id="faqs"
                    onClick={handleNavClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                    className="nav-item"
                >
                    FAQs
                </button>
            </div>
        </div>
    );
}