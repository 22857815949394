import Toggle from "../components/toggle";

export default function LondonEatsToggle() {
    return (
        <Toggle title="Places to eat and drink in London" blurb="There are literally too many amazing restaurants in London to list. A small selection of our best spots are:">
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Dishoom
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        A famous Indian food chain. There are a few in London and one in Edinburgh. It's the best Indian food outside of India. 
                        Matt loves the black chole. They don't take dinner bookings, so you might have to wait in line a while. 
                        They do take bookings for brunch, which is also delicious.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.dishoom.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Champor Champor
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Thai fusion restaurant with really creative dishes. The flavor blends are absolutely top notch. This is our go-to small-celebrations restaurant (birthdays, etc.)
                    </p>
                    <a
                        className="item-link"
                        href="https://www.champor-champor.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Ottolenghi (Islington)
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        There are a few Ottolenghi's around. We personally like the one in Angel (Islington) the best, but it's also the closest to us, so we might be biased. 
                        They have delicious sharing plates, and their bakery is always bursting with tarts and desserts.
                    </p>
                    <a
                        className="item-link"
                        href="https://ottolenghi.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Tofu Vegan
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Amazing vegan Chinese food. You honestly won't even know it's vegan. They regularly make the list of top restaurants in London.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.tofuvegan.com/home"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Trullo
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Fancier and pricier, but well worth the splurge. Top-notch Italian food. This was the first restaurant we ate at after Covid Lockdown.
                        Did we cry? We won't say.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.trullorestaurant.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Padella
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        The sister restaurant to Trullo. It offers smaller sharing plates that are slightly cheaper than the full Trullo dishes.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.padella.co/#home"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    The Tamil Prince
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        A simple yet incredibly effective Indian small plates menu.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.thetamilprince.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Liman
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        Good Turkish food in a quiet, charming restaurant off the beaten track. Matt and Fiona firmly believe they have the best babaganoush in London.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.liman.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Sticks'n'Sushi
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        A chain of sushi restaurants that also serve yakitori (the sticks), for those who prefer their food cooked.
                        We go here all the time when friends visit from out of town. It's fun for anyone who likes either sushi or sticks.
                    </p>
                    <a
                        className="item-link"
                        href="https://sticksnsushi.com/en"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    Drinks
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        You can go to basically any pub. No matter where you are in London, you're not more than a 5 minute walk from a pub. The list here has
                        some great options, spread across the city.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.timeout.com/london/bars-and-pubs/the-best-pubs-in-london"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
            <div className="toggle-row line-top">
                <p className="text-lower row-item">
                    More food
                </p>
                <div className="row-item">
                    <p className="text-lower">
                        TimeOut is the go-to London blog for what's hot and trendy. There are plenty more dining options on this list.
                    </p>
                    <a
                        className="item-link"
                        href="https://www.timeout.com/london/restaurants/best-restaurants-in-london"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Read More
                    </a>
                </div>
            </div>
        </Toggle>
    );
}