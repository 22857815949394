import { useState, useEffect } from 'react';

import LoadingWheel from '../components/loadingWheel';
import { ApiService } from '../services/apiService';
import { CookieSerivce } from '../services/cookieService';

import LineSVG from "../assets/svg/line";

/* Dear Puzzle Solver, viewing these hidden routes is not the intended way to solve the puzzle. 
If you view these hidden pages, you will likely spoil the solutions. If you wish to solve the puzzle the intended 
way, take a close look at the save the date picture we sent, as that will lead you through to these pages organically.*/
export default function PuzzleCentralPage() {
    const memoryCookieName = "memorySubmitted";
    const [nameInput, setNameInput] = useState('');
    const [memoryInput, setMemoryInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [submitComplete, setSubmitComplete] = useState(CookieSerivce.getCookie(memoryCookieName) ? true : false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Use this to clear cookies in debug mode
        // document.cookie = memoryCookieName + "=false; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    const submitClicked = () => {
        handleSubmit();
    }

    const handleSubmit = () => {
        if (nameInput === "" || memoryInput === "") {
            console.log("Must enter name and memory");
            return;
        }

        setIsLoading(true);

        ApiService.createMemory({ name: nameInput, memory: memoryInput }).then((result) => {
            setErrorMessage('');
            setSubmitComplete(false);
            setIsLoading(false);
            if (result.id) {
                setNameInput('');
                setMemoryInput('');
                setSubmitComplete(true);
                document.cookie = memoryCookieName + "=true";
            } else {
                setErrorMessage('Something went wrong. Please try again later');
            }
        });
    }

    return (
        <main className="line-top line-bottom">
            <div className="main-inner text-center">
                <div className="row-bottom">
                    <div className="text-container">
                        <div>
                            <h2 className="text-italic">A story of love and adventure</h2>
                            <div>
                                <p className="text-lower">
                                    <br></br>
                                    <a href="/❤️">FionaAndMatt.com/❤️</a>
                                    <br></br>
                                    (keep me safe, you'll want to come back here soon)
                                </p>
                            </div>
                        </div>
                        <LineSVG />
                        <div>
                            <h2>Part I: The Past (Memories)</h2>
                            <p className="text-lower">
                                <br></br>
                                <br></br>
                                Congratulations on completing Part I!
                                <br></br>
                                <br></br>
                                After finding each other, Fiona and Matt quickly set out on
                                adventures. Small moments together became memories that might be
                                set aside or lost, but could be rediscovered and relived with a smile.
                                <br></br>
                                <br></br>
                                When sitting in the same room, happy memories could be made with a joke or a hug.
                                If they were apart, Fiona and Matt shared their lives through texts, silly
                                emojis, selfies, even puzzles - anything that could conceivably
                                be used to say “I love you”.
                                <br></br>
                                <br></br>
                                Some memories were made by just the two of them, but their story is
                                far from complete unless they're reading the memories made with family and friends.
                                <br></br>
                                <br></br>
                                Please help Matt and Fiona rediscover moments by sharing a
                                memory you've held onto. Silly or serious, anything is welcome.
                                <br></br>
                                <br></br>
                            </p>
                            {!submitComplete && !isLoading ? (
                                <div>
                                    <br></br>
                                    Your name:{" "}
                                    <input
                                        type="text"
                                        value={nameInput}
                                        onInput={(e) => setNameInput(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    ></input>
                                    <br></br>A memory:
                                    <textarea
                                        rows="20"
                                        cols="50"
                                        value={memoryInput}
                                        onInput={(e) => setMemoryInput(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    ></textarea>
                                    <br></br>
                                    <button className="cta" onClick={submitClicked}>Share</button>
                                    <br></br>
                                </div>
                            ) : (
                                <div />
                            )}
                            {isLoading ? <LoadingWheel /> : <div />}
                            {submitComplete ? (
                                <div className="text-italic text-small text-lower">Thank you for sending us that memory!</div>
                            ) : (
                                <div />
                            )}
                            {errorMessage ? (
                                <div>There was a problem. Please try again later.</div>
                            ) : (
                                <div />
                            )}
                            <div className="row-top text-lower">
                                <p>Puzzle solutions:</p>
                                <br></br>
                                <a href="/std">Save the Date</a>
                                <br></br>
                                <a href="/otp">One Time Code Puzzle</a>
                                <br></br>
                                <a href="/photo-puzzle">Photo Puzzle</a>
                                <br></br>
                                <a href="/photo-puzzle">Emoji Texting Puzzle</a>
                                <br></br>
                            </div>
                        </div>
                        <LineSVG />
                        <div>
                            <h2>Part II: ...</h2>
                            <br></br>
                            <p className="text-lower">
                                The rest is still unwritten...for now. We'll let you know when to
                                turn to the next page. It will be a few months, so you can rest for a while.
                            </p>
                        </div>
                        <LineSVG />
                        <div>
                            <h2>Part III: ...</h2>
                            <br></br>
                            <p className="text-lower">
                                The rest is still unwritten...for now. We'll let you know when to
                                turn to the next page. It will be after Part II, so you've got time.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}